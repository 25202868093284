/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import { createTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../../styles.scss';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { navigate } from 'gatsby-link';
import { themeStyles } from '../../../styles';
import ThemeContext from '../../../utils/theme-context';
import { myStyles } from './styles';
import HeaderButton from './../headerButton';

export default function Links({ menuData, parentCallback, headerButton, logout, ast, siteType, isAst, leadFormDetails}) {
  const classes = myStyles();
  const theme = createTheme({});
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const partnerTheme = useContext(ThemeContext);
  const globalthemClasses = themeStyles(partnerTheme);

  function handleClick(e, menuItem) {
    if (menuItem?.menuItems !== null) {
      setAnchorEl(e.currentTarget);
    } else if (menuItem.isInternalLink === true) {
      navigate(`/${menuItem.menuLink?.link.referenceToPage.pageName}`);
    } else if (menuItem?.isParallax === true) {
      navigate(`/#${menuItem.menuLink?.link.actionId}`);
    } else {
      navigate(`${menuItem?.menuLink?.link?.url}`);
    }
  }

  const handleClose = (path) => {
    navigate(path);
  };
  const handleOutSideClose = () => {
    setAnchorEl(null);
  };
  const menuLink = (event) => {
    event.stopPropagation();
    parentCallback(false);
  };
  return (
    <Typography className={classes.root}>
      <>
        { (!isMobile)
          ? (
            <>
              <nav aria-label='header-links'>
                <ul className="menu-item">
                  {menuData?.map((menuItem) => (
                    <li key={menuItem.id} className={globalthemClasses.customMargin}>
                      {menuItem.menuItems != null ? (
                        <Button
                          disableFocusRipple
                          id={`${menuItem.menuLabel}-btn`}
                          size="large"
                          className={globalthemClasses.menuColor}
                          onClick={(e) => handleClick(e, menuItem)}
                          aria-controls="simple-menu"
                          aria-expanded={menuItem.menuItems != null && Boolean(anchorEl)}
                        >
                          {menuItem.menuLabel}
                          {' '}
                          <span className="arrow">{menuItem.menuItems != null && <ChevronRightIcon />}</span>
                        </Button>
                      ) : (
                        <Button
                          disableFocusRipple
                          id={`${menuItem.menuLabel}-btn`}
                          size="large"
                          className={globalthemClasses.menuColor}
                          onClick={(e) => handleClick(e, menuItem)}
                        >
                          {menuItem.menuLabel}
                          {' '}
                          <span className="arrow">{menuItem.menuItems != null && <ChevronRightIcon />}</span>
                        </Button>
                      )}
                      {menuItem.menuItems != null
                          && (
                          <Menu
                            MenuListProps={{ id: 'simple-menu', role: 'list' }}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleOutSideClose}
                          >
                            {menuItem.menuItems != null && menuItem.menuItems.map((_item) => (
                              <MenuItem className={globalthemClasses.menuColor} key={_item.id} role='listitem' onClick={() => handleClose(`/${_item?.link?.referenceToPage?.pageName}`)}>{_item.label}</MenuItem>
                            ))}
                          </Menu>
                          )}
                    </li>
                  ))}
                  <li>
                    <HeaderButton headerButton={headerButton} logout={logout} ast={ast} siteType={siteType} isAst={isAst} leadFormDetails />
                  </li>
                </ul>
              </nav>
            </>
          )
          : (
            <>
              {menuData?.map((menuItem) => {
                const isNewTab = !menuItem.isInternalLink && menuItem?.menuLink?.link?.openInANewTab ? 'blank' : '';
                return (
                  <Accordion
                    className={classes.accordian}
                    key={menuItem.id}
                  >
                    <AccordionSummary
                      expandIcon={menuItem.menuItems && (<ExpandMoreIcon />)}
                      aria-controls="panel1a-content"
                      aria-label={menuItem.menuLabel}
                      id="panel1a-header"
                      activeClassName="active"
                      className={classes.accordionSummary}
                      style={menuItem.menuItems ? { height: 'auto', width: 'auto', paddingRight: '16px' } : { height: '0', width: 'maxContent', paddingRight: '0px' }}
                    >
                      <Typography className={classes.heading}>
                        <Link
                          id={`${menuItem.menuLabel}-link`}
                          activeClassName="active"
                          className={classes.anchorLinks}
                          onClick={menuLink}
                          target={isNewTab}
                          to={menuItem.isParallax ? `/#${menuItem.menuLink?.link.actionId}` : (!menuItem.isInternalLink ? `${menuItem?.menuLink?.link?.url}` : `/${menuItem.menuLink?.link.referenceToPage?.pageName}`)}
                          rel="noreferrer"
                        >
                          {menuItem.menuLabel}

                        </Link>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.subLink}>
                      {menuItem.menuItems != null && menuItem.menuItems.map((item, index) => (
                        <Link to={`/${item?.link?.referenceToPage?.pageName}`} className={classes.linkItem} activeClassName="active" key={index}>{item.label}</Link>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
      </>
    </Typography>
  );
}
